import anime from 'animejs'
import ScrollMagic from "scrollmagic";

const logoAnimation = anime({
  targets: '.logo',
  translateY: '60%',
  duration: 1000,
  easing: 'easeInOutCubic',
  opacity: 1,
  autoplay: false
});

const titleIntro = anime({
  targets: '.site__introduction--heading',
  translateY: [25,0],
  opacity: 1,
  autoplay: false
});

const titleMag = anime({
  targets: '.site__kbdmag--heading',
  translateY: [25,0],
  opacity: 1,
  autoplay: false
});

const titleInsta = anime({
  targets: '.site__insta--heading',
  translateY: [25,0],
  opacity: 1,
  autoplay: false
});

const graphicInsta = anime({
  targets: '.site__insta--visual',
  translateY: [25,0],
  opacity: 1,
  autoplay: false
});

const controller = new ScrollMagic.Controller();

new ScrollMagic.Scene({
  triggerElement: ".site__header",
  duration: 1000
})
  .on("progress", (scroll) => {
    const seekValue = 1000 * scroll.progress;
    logoAnimation.seek(seekValue);
  })
  .addTo(controller);

new ScrollMagic.Scene({
	triggerElement: ".site__introduction--wrapper",
	duration: 1500
})
	.on('progress', (scroll) => {
		const seekValue = 1000 * scroll.progress;
		titleIntro.seek(seekValue);
	})
	.addTo(controller);

new ScrollMagic.Scene({
	triggerElement: ".site__kbdmag--wrapper",
	duration: 1500
})
	.on('progress', (scroll) => {
		const seekValue = 1000 * scroll.progress;
		titleMag.seek(seekValue);
	})
	.addTo(controller);

new ScrollMagic.Scene({
	triggerElement: ".site__insta--wrapper",
	duration: 2000
})
	.on('progress', (scroll) => {
		const seekValue = 1000 * scroll.progress;
		titleInsta.seek(seekValue);
		graphicInsta.seek(seekValue);
	})
	.addTo(controller);